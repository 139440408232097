import { useState, useEffect, Fragment } from 'react';
// import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import bleatLogoWhite from '../../../Assets/Images/bleat-logo-white.png';
import bleatLogoBlack from '../../../Assets/Images/bleat-logo-black.png';
import bleatMobileBlack from '../../../Assets/Images/bleat-logo-bottom-white.svg';
import bleatMobileWhite from '../../../Assets/Images/bleat-logo-bottom-white.svg';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import bleatLogo from '../../../Assets/Images/bleat-logo.svg';
import footerBottomLogo from '../../../Assets/Images/footer-logo-desktop.png';
import footerBottomLogoMobile from '../../../Assets/Images/footer-logo-mobile.png';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';


const Footer = props => {

  const { windowWidth } = useWindowDimensions();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname.split("/")[1];
  const [logo, setLogo] = useState(bleatMobileWhite);
  const [isBgDark, setIsBgDark] = useState(false);

    // console.log(currentLocation);
    // console.log(currentLocation.pathname.split("/")[1]);

  useEffect(() => {

    // paths with dark background
    // const pathsWithDarkBg = ["results", "invite-team", "invite-team-thanks", "dashboard"];
    const pathsWithDarkBg = ["results", "dashboard"];

    if (pathsWithDarkBg.includes(currentPath)) {
      // setLogo(bleatMobileWhite);
      setIsBgDark(true);
    } else {
      // setLogo(bleatMobileBlack);
      setIsBgDark(false);
    }

  }, [currentLocation, currentPath]);

  useEffect(() => {
    if (isBgDark) {
      if (windowWidth > 830) setLogo(bleatLogoWhite);
      else setLogo(bleatLogoWhite);
    }
    else {
      if (windowWidth > 830) setLogo(bleatLogoBlack);
      else setLogo(bleatLogoBlack);
    }
  }, [isBgDark, windowWidth]);


  return <Fragment>

    <footer style={{ minHeight: '70px' }}>

      {/* <div className={'bleat-logo'}>
          {windowWidth > 830 ? <Link to="/start" className="logo-right" style={{}}><img src={logo} width="auto" height={67.5} alt="Bleat" /></Link> : <Link to="/" className="logo-right"></Link>}
          </div> */}

      {windowWidth <= 830 ?
        <div className="bleat-logo-mobile">
          <img src={logo} alt="bleat logo" height="73px" width="auto" />
        </div> :

        <div className="bleat-logo" style={{ margin: 'auto' }}>
          <img src={logo} alt="bleat logo" height="73px" width="auto" />
        </div>

      }

    </footer>
  </Fragment>
}

export default Footer;
