import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import analyzeIcon from '../../Assets/Images/analyze.svg';
import alignIcon from '../.././Assets/Images/align.svg'
import advanceIcon from '../../Assets/Images/advance.svg';
import Testimonial from '../Testimonial/Testimonial.jsx';
import ShareIcon from "../../Assets/Images/share-icon.png"

const InviteInfo = (props) => {

  const { id } = useParams();


  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Champion greater financial discipline</h3>
        <p>Invite more church leaders to take this assessment to help them assess and improve their financial practices. Your invitation can go a long way in aligning the Church’s financial footprints with its mission and vision.</p>

        {/* <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Assess</span><br />
            Measure your performance in key areas concerning engagement
          </div>

          <div className="reason">
            <img src={alignIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Benchmark</span><br />
            Check how you compare with similarly-sized churches
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Calibrate</span><br />
            Align around action based on carefully assessed data
          </div>

        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY PEERS</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}


      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}

    </div>
  );
}

export default InviteInfo;
