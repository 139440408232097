import { Link } from "react-router-dom";
import logoLeft from '../../Assets/Images/acst-logo-vertical.png';
import "../../App.scss";

const LeftLogo = () => {
	return (
		<div className="logo-left-container">
			{/* <Link to="/start" className="logo-left"><img src={logoLeft} alt="Logo" /></Link> */}
			<img src={logoLeft} alt="Logo" />
		</div>
	);
}

export default LeftLogo;
