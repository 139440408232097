import { useState, useEffect, createContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AssessmentQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => { },
  updateAnswer: (updateAnswer) => { },
  updateTextAnswer: (updateTextAnswer) => { },
});

export function AssessmentQuestionContextProvider(props) {

  const location = useLocation();

  const [questions, setQuestions] = useState([]);

  // console.log(location.pathname.split("/").includes("review"));
  useEffect(() => {
    if (location.pathname.split("/").includes("review")) {
      let localStoredQuestions = JSON.parse(localStorage.getItem("assessmentAnswers"));
      if (localStoredQuestions?.length) {
        setQuestions(localStoredQuestions);
      }
    }
  }, [location]);

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answer = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answerTxt = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }
  
  function updateCheckboxAnswerHandler(questionId, answersArray) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answers = answersArray;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  const context = {
    questions: questions,
    addQuestions: addQuestionsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    updateCheckboxAnswer: updateCheckboxAnswerHandler
  };

  return <AssessmentQuestionContext.Provider value={context}>
    {props.children}
  </AssessmentQuestionContext.Provider>
}

export default AssessmentQuestionContext;
