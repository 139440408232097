import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link, useParams } from "react-router-dom";
import Tabs from '.././Tabs/Tabs.jsx';
import { Accordion, AccordionSummary, AccordionDetails, LinearProgress, CircularProgress, Slider, Switch, Box } from '@mui/material';

import prevArrow from '../../Assets/Images/prev-arrow.svg';
import nextArrow from '../../Assets/Images/next-arrow.svg';

import { Add, ExpandLess, ExpandMore, Remove } from '@mui/icons-material';
import UrgencyScore from '../../Components/UrgencyScore/UrgencyScore.jsx';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { sectionsText } from '../../globalFunctions';

import scheduledImage from "../../Assets/Images/schedule-image.png";
import MobileDownArrow from "../../Assets/Images/DropDownArrowRed.svg";

import DonutChartAccordion from '../../Components/DonutChartAccordion/DonutChartAccordion.jsx';
import ClickableCircularProgressBars from '../../Components/ClickableCircularProgressBars.jsx';
import InviteInfo from '../../Components/InviteInfo/InviteInfo.jsx';
import Testimonial from '../../Components/Testimonial/Testimonial.jsx';

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const Dashboard = (props) => {
  
  console.log("DASHBOARD", window.location.pathname);
  const { id } = useParams();
  const { windowWidth } = useWindowDimensions();
  
  const [isLoading, setIsLoading] = useState(true);
  const [currentView, setCurrentView] = useState(0);

  const [toggleIndividual, setToggleIndividual] = useState(0);
  const [toggleSection, setToggleSection] = useState(1);
  const [toggleMore, setToggleMore] = useState(0);
  const [isMember, setIsMember] = useState(false);
  const [members, setMembers] = useState([]);
  const [selected, setSelected] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [hurdles, setHurdles] = useState([]);
  const [enablers, setEnablers] = useState([]);
  const [tops, setTops] = useState([]);
  const [bottoms, setBottoms] = useState([]);

  const [score, setScore] = useState(0);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScore7, setSubScore7] = useState(0);
  const [subScore8, setSubScore8] = useState(0);
  const [urgencyVote1, setUrgencyVote1] = useState(0);
  const [urgencyVote2, setUrgencyVote2] = useState(0);
  const [urgencyVote3, setUrgencyVote3] = useState(0);
  const [urgencyVote4, setUrgencyVote4] = useState(0);
  const [urgencyVote5, setUrgencyVote5] = useState(0);
  const [urgencyScore, setUrgencyScore] = useState(0);
  const [memberSection1, setMemberSection1] = useState([]);
  const [memberSection2, setMemberSection2] = useState([]);
  const [memberSection3, setMemberSection3] = useState([]);
  const [memberSection4, setMemberSection4] = useState([]);
  const [subScores, setSubScores] = useState([0, 0, 0, 0]);

  const [memberScore, setMemberScore] = useState(0);
  const [memberName, setMemberName] = useState(0);
  const [memSubScore1, setMemSubScore1] = useState(0);
  const [memSubScore2, setMemSubScore2] = useState(0);
  const [memSubScore3, setMemSubScore3] = useState(0);
  const [memSubScore4, setMemSubScore4] = useState(0);
  const [memSubScore5, setMemSubScore5] = useState(0);
  const [memSubScore6, setMemSubScore6] = useState(0);
  const [memSubScore7, setMemSubScore7] = useState(0);
  const [memSubScore8, setMemSubScore8] = useState(0);
  const [memUrgencyScore, setMemUrgencyScore] = useState(0);
  const [additionalQuestions, setAdditionalQuestions] = useState([]);

  const [loadIndividualResults, setLoadIndividualResults] = useState(false);
  const [memberApiData, setMemberApiData] = useState();
  const [allMembersApiData, setAllMembersApiData] = useState();

  const [chartsData, setChartsData] = useState();

  const [dashQuestions, setDashQuestions] = useState();
  const [isMemberListOpen, setIsMemberListOpen] = useState(false);


  const scores = [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8];
  const memScores = [memSubScore1, memSubScore2, memSubScore3, memSubScore4, memSubScore5, memSubScore6, memSubScore7, memSubScore8];

  let urgencyPercent = 60.0;
  if (window.innerWidth > 650) {
    urgencyPercent = 63.9;
  }

  // useEffect(() => {
  //   // basic check for API data reliability
  //   if(memberApiData?.score > 0 && memberApiData?.status === "success") {
  //     setChartsData([
  //       { title: memberApiData?.chart1Title, values: memberApiData?.chart1Values, selectedAns: memberApiData?.selected1Answer },
  //       { title: memberApiData?.chart2Title, values: memberApiData?.chart2Values, selectedAns: memberApiData?.selected2Answer },
  //       { title: memberApiData?.chart3Title, values: memberApiData?.chart3Values, selectedAns: memberApiData?.selected3Answer },
  //       { title: memberApiData?.chart4Title, values: memberApiData?.chart4Values, selectedAns: memberApiData?.selected4Answer },
  //       { title: memberApiData?.chart5Title, values: memberApiData?.chart5Values, selectedAns: memberApiData?.selected5Answer },
  //     ]);
  //   }
  // }, [memberApiData]);

  useEffect(() => {
    // basic check for API data reliability 
    if (memberApiData?.score > 0 && memberApiData?.status === "success") {

      const score1 = memberApiData?.chart1Values?.find(val => val.name === memberApiData?.selected1Answer)?.y;
      const score2 = memberApiData?.chart2Values?.find(val => val.name === memberApiData?.selected2Answer)?.y;
      const score3 = memberApiData?.chart3Values?.find(val => val.name === memberApiData?.selected3Answer)?.y;
      const score4 = memberApiData?.chart4Values?.find(val => val.name === memberApiData?.selected4Answer)?.y;
      const score5 = memberApiData?.chart5Values?.find(val => val.name === memberApiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "COLLABORATION",
          order: 1, values: memberApiData?.chart1Values, answer: { name: memberApiData?.selected1Answer, y: score1 },
          dashQuestions: dashQuestions?.[0]?.questions?.slice(1)
        },
        {
          title: "POSITIONING",
          order: 2, values: memberApiData?.chart2Values, answer: { name: memberApiData?.selected2Answer, y: score2 },
          dashQuestions: dashQuestions?.[1]?.questions?.slice(1)
        },
        {
          title: "PREPARATION",
          order: 3, values: memberApiData?.chart3Values, answer: { name: memberApiData?.selected3Answer, y: score3 },
          dashQuestions: dashQuestions?.[2]?.questions?.slice(1)
        },
        {
          title: "ENGAGEMENT",
          order: 4, values: memberApiData?.chart4Values, answer: { name: memberApiData?.selected4Answer, y: score4 },
          dashQuestions: dashQuestions?.[3]?.questions?.slice(1)
        },
        {
          title: "OUTCOMES",
          order: 5, values: memberApiData?.chart5Values, answer: { name: memberApiData?.selected5Answer, y: score5 },
          dashQuestions: dashQuestions?.[4]?.questions?.slice(1)
        },
      ]);
    }
  }, [dashQuestions, memberApiData]);

  useEffect(() => {

    let sectionsAndQs = [];
    sections?.forEach(sec => {
      let questionsInThisSection = allQuestions?.filter(q => q.sectionId === sec.id);
      questionsInThisSection?.forEach(que => {
        let ans = memberApiData?.answers?.find(memAns => memAns.question_id === que.id);
        que.answer = ans?.answer;
      })
      sectionsAndQs.push({ section: sec.section, sectionId: sec.id, questions: questionsInThisSection });
    });
    console.log(sectionsAndQs);
    setDashQuestions(sectionsAndQs);

  }, [memberApiData, memberApiData?.answers, questions, sections]);

  console.log("\n\ndashQuestions", dashQuestions, "\n\n");

  useEffect(() => {
    // document.body.style.backgroundColor ="#292829";
    fetchResults();
    fetchAdditionalQuestions();
  }, []);

  useEffect(() => {
    // to animate subScores on results page 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    // 	clearTimeout(timer);
    // };
    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  function allMembersDataSetterFunc(data) {
    setScore(data.score);
    setSubScore1(data.subScore1);
    setSubScore2(data.subScore2);
    setSubScore3(data.subScore3);
    setSubScore4(data.subScore4);
    setSubScore5(data.subScore5);
    setUrgencyScore(data.urgencyScore);
    setMembers(data.assessmentInfo);
    setQuestions(data.questionInfo);
    setAllQuestions(data.allQuestionInfo);
    setSections(data.sectionInfo);
    setHurdles(data.hurdles);
    setEnablers(data.enablers);
    setIsMember(data.isTeamMember);

    // console.log(data.questionInfo);

    setSelected(data.assessmentInfo[0].id);
    setMemberScore(data.assessmentInfo[0].score);
    setMemberName(data.assessmentInfo[0].fullName);
    setMemSubScore1(data.assessmentInfo[0].subScore1);
    setMemSubScore2(data.assessmentInfo[0].subScore2);
    setMemSubScore3(data.assessmentInfo[0].subScore3);
    setMemSubScore4(data.assessmentInfo[0].subScore4);
    setMemSubScore5(data.assessmentInfo[0].subScore5);
    setMemUrgencyScore(data.assessmentInfo[0].addAnswer1);

    let urgencyVote1 = 0;
    let urgencyVote2 = 0;
    let urgencyVote3 = 0;
    let urgencyVote4 = 0;
    let urgencyVote5 = 0;

    let section1Questions = data.questionInfo.filter(function (obj) { return obj.sectionId == 1; });
    section1Questions.forEach((question) => {
      let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection1(section1Questions);

    let section2Questions = data.questionInfo.filter(function (obj) { return obj.sectionId == 2; });
    section2Questions.forEach((question) => {
      let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection2(section2Questions);

    let section3Questions = data.questionInfo.filter(function (obj) { return obj.sectionId == 3; });
    section3Questions.forEach((question) => {
      let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection3(section3Questions);

    let section4Questions = data.questionInfo.filter(function (obj) { return obj.sectionId == 4; });
    section4Questions.forEach((question) => {
      let answer = data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection4(section4Questions);

    let tops = data.questionTop.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
    let bottoms = data.questionBottom.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));

    let urgVote1 = 0;
    let urgVote2 = 0;
    let urgVote3 = 0;
    let urgVote4 = 0;
    let urgVote5 = 0;

    data.assessmentInfo.forEach((member) => {
      if (member.addAnswer1 == 1) urgVote1++;
      else if (member.addAnswer1 == 2) urgVote2++;
      else if (member.addAnswer1 == 3) urgVote3++;
      else if (member.addAnswer1 == 4) urgVote4++;
      else if (member.addAnswer1 == 5) urgVote5++;
    })

    setUrgencyVote1(urgVote1);
    setUrgencyVote2(urgVote2);
    setUrgencyVote3(urgVote3);
    setUrgencyVote4(urgVote4);
    setUrgencyVote5(urgVote5);

    setTops(data.questionTopV3);
    setBottoms(data.questionBottomV3);
    setIsLoading(false);

    console.log("tops", tops);
    console.log("bottoms", bottoms);
    console.log("questions", questions);
  }

  const fetchResults = () => {

    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/dashboard', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        setAllMembersApiData(data);
        allMembersDataSetterFunc(data);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      setIsLoading(false);
      console.error("Failed to fetch results...", err);
    });
  }

  const fetchIndividualResults = (uniqueId) => {
    setIsLoading(true);
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + uniqueId + "/results", {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        setMemberApiData(data);
        setScore(data.score);
        setSubScore1(data.subScore1);
        setSubScore2(data.subScore2);
        setSubScore3(data.subScore3);
        setSubScore4(data.subScore4);
        setSubScore5(data.subScore5);

        // setUrgencyScore(data.urgencyScore);
        // setMembers(data.assessmentInfo);
        // setQuestions(data.questionInfo);
        // setSections(data.sectionInfo);
        // setHurdles(data.hurdles);
        // setEnablers(data.enablers);
        // setIsMember(data.isTeamMember);

        // console.log(data.questionInfo);

        // setSelected(data.id);
        // setMemberScore(data.score);
        // setMemberName(data.fullName);
        // setMemberName(data.name);
        // setMemSubScore1(data.subScore1);
        // setMemSubScore2(data.subScore2);
        // setMemSubScore3(data.subScore3);
        // setMemSubScore4(data.subScore4);
        // setMemSubScore5(data.subScore5);
        // setMemUrgencyScore(data.addAnswer1);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });

  }

  useEffect(() => {
    let member = members.find(function (obj) { return obj.id == selected; });
    console.log("member", member);
    if (loadIndividualResults) fetchIndividualResults(member?.uniqueId);
    else if (allMembersApiData?.status === "success") allMembersDataSetterFunc(allMembersApiData);
  }, [selected, loadIndividualResults, allMembersApiData]);

  console.log("selected", selected);

  const fetchAdditionalQuestions = () => {

    let clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setAdditionalQuestions(data.questions);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to fetch additional questions...", err));
  }

  // there are 2 urgencyScore like bars for 2 questions for both, team and individual 
  const teamVotesQ1 = [0, 0, 0, 0, 0];
  const teamVotesQ2 = [0, 0, 0, 0, 0];
  const individualVotesQ1 = [0, 0, 0, 0, 0];
  const individualVotesQ2 = [0, 0, 0, 0, 0];

  const teamAnswers3 = [];
  const teamAnswers4 = [];
  const individualAnswer3 = [];
  const individualAnswer4 = [];

  const hurdlesQuestion = additionalQuestions?.find(q => q.id == 1074)?.question;
  const enablersQuestion = additionalQuestions?.find(q => q.id == 1073)?.question;

  members?.forEach((member) => {
    let question1 = member.answers?.find(a => a.question_id == 539);
    let question2 = member.answers?.find(a => a.question_id == 1072);
    let question3 = member.answers?.find(a => a.question_id == 1073);
    let question4 = member.answers?.find(a => a.question_id == 1074);
    if (question1?.answer) teamVotesQ1[question1?.answer - 1] += 1;
    if (question2?.answer) teamVotesQ2[question2?.answer - 1] += 1;
    if (question3?.answer_txt) teamAnswers3.push(question3?.answer_txt);
    if (question4?.answer_txt) teamAnswers4.push(question4?.answer_txt);
  })
  console.log("teamAnswers3", teamAnswers3);
  console.log("teamAnswers4", teamAnswers4);

  const individualMember = members?.find(m => m.id == selected);
  let question1 = individualMember?.answers?.find(a => a.question_id == 539);
  let question2 = individualMember?.answers?.find(a => a.question_id == 1072);
  let question3 = individualMember?.answers?.find(a => a.question_id == 1073);
  let question4 = individualMember?.answers?.find(a => a.question_id == 1074);
  if (question1?.answer) individualVotesQ1[question1?.answer - 1] += 1;
  if (question2?.answer) individualVotesQ2[question2?.answer - 1] += 1;
  if (question3?.answer_txt) individualAnswer3.push(question3?.answer_txt);
  if (question4?.answer_txt) individualAnswer4.push(question4?.answer_txt);

  console.log("individualMember", individualMember);
  console.log("teamVotesQ1", teamVotesQ1, "teamVotesQ2", teamVotesQ2);
  console.log("individualVotesQ1", individualVotesQ1, "individualVotesQ2", individualVotesQ2);

  // const headerText1 = questions?.find(q => q.id == 374)?.question;
  const headerText2 = additionalQuestions?.find(q => q.id == 1072)?.question;
  const headerText1 = "TEAM AVERAGE URGENCY SCORE";
  const headerText1Individual = `${memberName?.toString()?.split(" ")?.[0]?.toUpperCase()}'s URGENCY SCORE`;
  // const labels1 = questions?.find(q => q.id == 539)?.answers?.map(a => a?.desktop_text);
  // const labels2 = questions?.find(q => q.id == 539)?.answers?.map(a => a?.desktop_text);
  const labels1 = additionalQuestions?.find(q => q.id == 1072)?.answers?.map(a => a?.desktop_text);
  const labels2 = additionalQuestions?.find(q => q.id == 1072)?.answers?.map(a => a?.desktop_text);

  const urgencyComponentPropsQ1Team = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText1, labels: labels1, votes: teamVotesQ1, team: true };
  const urgencyComponentPropsQ2Team = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText2, labels: labels2, votes: teamVotesQ2, team: true };
  const urgencyComponentPropsQ1Individual = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText1Individual, labels: labels1, votes: individualVotesQ1, team: false };
  const urgencyComponentPropsQ2Individual = { urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5, headerText: headerText2, labels: labels2, votes: individualVotesQ2, team: false };


  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
      setLoadIndividualResults(false);
    } else {
      setLoadIndividualResults(true);
      setCurrentView(1);
      setToggleIndividual(0);
      changeIndividualAssessment(members[0].id)
    }
  }

  const updateIndividualAssessment = (e) => {
    let memberId = e.target.value;
    changeIndividualAssessment(memberId);
  }

  const changeIndividualAssessment = (id) => {
    let member = members.find(function (obj) { return obj.id == id; });
    console.log(member);
    console.log(questions);
    let sectionIds = [];
    sections.forEach((section) => {
      sectionIds.push(section.id);
    });
    console.log(sectionIds);
    setSelected(member.id);
    setMemberScore(member.score);
    setMemberName(member.fullName);
    setMemSubScore1(member.subScore1);
    setMemSubScore2(member.subScore2);
    setMemSubScore3(member.subScore3);
    setMemSubScore4(member.subScore4);
    setMemSubScore5(member.subScore5);
    setMemUrgencyScore(member.addAnswer1);

    let section1Questions = questions.filter(function (obj) { return obj.sectionId == sectionIds[0]; });
    section1Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });

    setMemberSection1(section1Questions);

    let section2Questions = questions.filter(function (obj) { return obj.sectionId == sectionIds[1]; });
    section2Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection2(section2Questions);

    let section3Questions = questions.filter(function (obj) { return obj.sectionId == sectionIds[2]; });
    section3Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection3(section3Questions);

    let section4Questions = questions.filter(function (obj) { return obj.sectionId == sectionIds[3]; });
    section4Questions.forEach((question) => {
      let answer = member.answers.find(function (obj) { return obj.question_id == question.id });
      question.answer = answer.answer;
    });
    setMemberSection4(section4Questions);

  }

  const showAnswerText = (answer, questionId) => {

    let question = questions.find(function (obj) { return obj.id == questionId; });
    let answerType = "green";

    if (question.answers.length == 2) {
      if (answer == 1) { answerType = "red"; }
      else if (answer == 2) { answerType = "green"; }
    } else if (question.answers.length == 3) {
      if (answer == 1) { answerType = "red"; }
      else if (answer == 2) { answerType = "orange"; }
      else if (answer == 3) { answerType = "green"; }
    } else if (question.answers.length == 4) {
      if (answer == 1) { answerType = "red"; }
      else if (answer == 2) { answerType = "red"; }
      else if (answer == 3) { answerType = "green"; }
      else if (answer == 4) { answerType = "green"; }
    } else if (question.answers.length == 5) {
      if (answer == 1) { answerType = "red"; }
      else if (answer == 2) { answerType = "red"; }
      else if (answer == 3) { answerType = "orange"; }
      else if (answer == 4) { answerType = "green"; }
      else if (answer == 5) { answerType = "green"; }
    }
    let answerVal = question.answers.find(function (obj) { return obj.value == answer; });

    if (answerVal && answerVal.desktop_text) {
      if (answerType == "red") {
        return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      } else if (answerType == "orange") {
        return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      } else if (answerType == "green") {
        return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      }
    }
  }

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores?.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);

  const showTitleText = () => {
    // if (score > 0 && score <= 45) {
    //   // return <h1><span className="user-name">{memberName?.split(" ")?.[0]}</span>, your ChMS is Sub-optimal</h1>
    // } else if (score > 45 && score <= 70) {
    //   return <h1>{memberName}, your church meets expectations</h1>
    // }

    if (score >= 0 && score <= 50) {
      return <h1>It seems like there's significant scope to improve your impact in your marriage ministry!</h1>
    } else if (score > 50 && score <= 80) {
      return <h1>You're ready for greater impact in your marriage ministry!</h1>
    } else if (score > 80) {
      return <h1>Your current efforts seem to be elevating impact in your marriage ministry!</h1>
    }
  };

  const showScoreText = (scoreVal) => {
    let section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);
    if (allScoresSame) {
      return <div className="result-text">
        <p style={{ margin: "10px auto 30px" }}>We're glad to see you're maintaining a balanced focus across all fronts of your community engagement strategy.</p>
        <p style={{ margin: "10px auto 5px" }}>Here are some quick tips to elevate engagement through all areas and maximize your Kingdom impact:</p>
        <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
      </div>
    } else return <div className="result-text">
      <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the {highestScoreObject?.section?.toLowerCase()} front and pay special attention to {sortedSectionsAndSubScores[0]?.section?.toLowerCase()}.</p>
      <p style={{ margin: "10px auto 5px" }}>Here are some quick tips:</p>
      <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    </div>
    // if (scoreVal > 0 && scoreVal <= 50) {
    //   return <div className="result-text">
    // 		<p></p>
    //   </div>
    // } else if (scoreVal > 50 && scoreVal <= 80) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // } else if (scoreVal > 80) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // }
  };

  const toggleSectionDetails = (id) => {
    if (id == toggleSection) {
      setToggleSection(0);
    } else {
      setToggleSection(id);
    }
  }

  const toggleChange = (e) => {
    setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const toggleSectionDisplay = (id) => {
    if (id == toggleSection) {
      return { display: "block" }
    } else {
      return { display: "none" }
    }
  }

  const toggleSectionIcon = (id) => {
    if (id == toggleSection) {
      return <Remove />
      // return <ExpandLess />
    } else {
      // return <ExpandMore />
      return <Add />
    }
  }

  const toggleDetails = (id) => {
    if (id == toggleMore) {
      setToggleMore(0);
    } else {
      setToggleMore(id);
    }
  }

  const toggleMoreDisplay = (id) => {
    if (id == toggleMore) {
      return { display: "block" }
    } else {
      return { display: "none" }
    }
  }

  const toggleMoreIcon = (id) => {
    if (id == toggleMore) {
      // return <Remove />
      return <ExpandLess />

    } else {
      // return <Add />
      return <ExpandMore />
    }
  }

  const getLeftMargin = (scoreVal) => {
    if (windowWidth <= 830) {
      if (scoreVal > 96 && scoreVal < 100) {
        return { left: 96.5 + "%" };
      } else if (scoreVal >= 100) {
        return { left: 97.5 + "%" };
      } else if (scoreVal > 0 && scoreVal < 3) {
        return { left: 2.5 + "%" };
      } else if (scoreVal <= 0) {
        return { left: 1.5 + "%" };
      } else {
        return { left: Math.round(scoreVal) + "%" };
      }
    } else {
      return { left: Math.round(scoreVal) + "%" };
    }
  };

  const getBarTextLeft = (scoreValue) => {
    if (window.innerWidth < 650 && scoreValue > 80) {
      return { left: "-170px" }
    }
    return { left: "-20px" }
  }

  const showProgressBarText = (scoreValue) => {
    // if (scoreValue >= 95) {
    //   return "Enjoying health";
    // } else if (scoreValue >= 80 && scoreValue < 95) {
    //   return "Improving health";
    // } else if (scoreValue >= 70 && scoreValue < 80) {
    //   return "Pursuing health";
    // } else if (scoreValue < 70) {
    //   return "Battling unhealthiness";
    // }
    // return "Your church scored"
  }


  const showSchedule = () => {
    return (
      <div className="schedule-container">
        <div className="avatar">
          <img src={scheduledImage} />
        </div>
        <h3>Schedule a call with Kim</h3>
        <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
        <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
        <div className="cta-btn">
          <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
            <Button>TALK TO KIM</Button>
          </a>
        </div>
      </div>
    );
  };


  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Get your team ready for Decision Day
              after all team members have taken the test.</p>
            <div className="cta-btn">
              <Link to={"#"} target="_blank">
                <Button>Let's Debreif</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };
  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return ( <InviteInfo /> )
  };
  const showTestimonial = () => {
    if (!isMember) {
        return (
            <Testimonial />
        );
    } else {
        return null;
    }
};



  if (isLoading) {
    return <div className="container dashboard loading"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div>
  }

  if (currentView == 1) {
    return (
      // <div style={{backgroundColor:"#435B63"}}>
      <div>
        <div className="individual-group">
          <button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
        </div>

        <div className="toggle-individual-group">
          <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
          <Switch
            checked={!toggleIndividual}
            onChange={toggleIndividualChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
        </div>

        <div className="container results">

          <div style={{ height: '20px' }}></div>
          <div className="selection-form">
            <div className="form-detail">
              <TextField select hiddenLabel value={selected} onClick={() => setIsMemberListOpen(!isMemberListOpen)} onChange={(e) => { updateIndividualAssessment(e) }} fullWidth variant="standard" size="normal" SelectProps={{
                IconComponent: () => <img className='down-arrow' src={MobileDownArrow} alt='arrow' onClick={() => setIsMemberListOpen(!isMemberListOpen)} />,
                MenuProps: { open: isMemberListOpen },
              }} InputProps={{ disableUnderline: true }} className="form-details-text">
                {members.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.fullName}'s
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-text">assessment score</div>
          </div>

          <div className="details-container" style={{ margin: "0 auto" }}>

            <div className="progress-bar">
              <div className="si_ass_result">
                <div className="si_ass_progress">
                  <div className="transparent" style={{width: `${100-(Math.round(score))}%`}}></div>
                  {/* <div className="progress-line" style={{ left: individualMember?.score + '%' }}> */}
                  {/* <div className="progress-line" style={{ left: memberApiData?.score + '%' }}>
                    <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div>
                  </div> */}
                  {/* <span className="msi_score" style={getLeftMargin(individualMember?.score)}>{individualMember?.score}%</span> */}
                  <span className="msi_score" style={getLeftMargin(memberApiData?.score)}>{Math.round(memberApiData?.score)}%</span>
                  <div className="score-arrow" style={{ left: Math.round(memberApiData?.score) + '%' }}></div>
                </div>
                <div className="pervalue">
                  <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                  <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                  {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                  {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                </div>


                <div className="si_pro_header">
                  <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                  {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
                </div>

                {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                {/* </div> */}

                <div className="pervalue-text">
                  <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Less<br />Effective</p>
                  {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                  <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>Somewhat<br />Effective</p>
                  {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                  <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Highly<br />Effective</p>
                </div>

              </div>
            </div>

            {/* {showScoreText(individualMember?.score)} */}
            {/* {showScoreText(memberApiData?.score)} */}
            <h3 className="pre-score-header">{memberApiData?.name}'s overall score is <b>{Math.round(memberApiData?.score)}%</b>.<br/>Below are {memberApiData?.name}'s scores per category.</h3>
            {/* <div className="spacer"></div>
						<div className="spacer"></div> */}

            <div className="score-section-container">

              {/* {
								sectionsAndSubScores.map((data, index) => (
									<div key={index} className="score-section">
										<p className="score-txt">{memScores[index]}%</p>
										<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memScores[index]} /></div>
										<p className="score-name">{ data.section }</p>
									</div>)
							)} */}
              {/* {sectionsAndSubScores?.map(sec => {
                return (
                  <div key={sec.section} className="score-section">
                    <p className="score-txt" style={{
                      // marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` 
                    }}>{Math.round(sec.score)}%</p>
                    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} />
                    </div>
                    <p className="score-name">{sec.section}</p>
                  </div>)
              })} */}

              <ClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} />


            </div>

            <h2 style={{ textAlign: "center", margin: "15px auto 45px", fontSize: windowWidth < 651 ? "18px" : "24px" }}>INDIVIDUAL SCORES</h2>

            {sectionsAndSubScores.map((data, index) => {
							
							let memberSection 		= [];
							if(index == 0) { 
								memberSection1.forEach((member) => {
									console.log(member);
									memberSection.push({id: member.id, question: member.question, answer: member.answer});
								});									
							} else if(index == 1) {
								memberSection2.forEach((member) => {
									memberSection.push({id: member.id, question: member.question, answer: member.answer});
								});
							} else if(index == 2) {
								memberSection3.forEach((member) => {
									memberSection.push({id: member.id, question: member.question, answer: member.answer});
								});
							} else if(index == 3) {
								memberSection4.forEach((member) => {
									memberSection.push({id: member.id, question: member.question, answer: member.answer});
								});
							} else if(index == 4) {
								memberSection4.forEach((member) => {
									memberSection.push({id: member.id, question: member.question, answer: member.answer});
								});
							}
							console.log(memberSection);

							return <div key={index} className="members-answered">
								<p className="section" onClick={() => toggleSectionDetails(index + 1)}>
									{data.section}
									<a className="section-toggle">
										{ toggleSectionIcon(index + 1) }
									</a>
								</p>
								<div style={ toggleSectionDisplay(index + 1) }>
									{
										memberSection.map((data, index) => (
											<div key={index} className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer, data.id)}</div>
										)
									)}
								</div>
							</div>
							}
						)}

            {/* <div className="charts-accordions-container">
              {chartsData?.map((chart, idx) => {
                return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                  <AccordionSummary expandIcon={<CustomExpandIcon />}>
                    <p style={{ fontWeight: 700 }}>{chart?.title}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={true} dashQuestions={chart.dashQuestions} />
                  </AccordionDetails>
                </Accordion>);
              })
              }
            </div> */}


            {/* <UrgencyScore {...{ urgencyComponentProps: urgencyComponentPropsQ1Individual }} /> */}
            {/* <UrgencyScore {...{ urgencyComponentProps: urgencyComponentPropsQ2Individual }} /> */}
            {/* <div className="hurdles-enablers">
              <h2 style={{ color: '#27282A' }}>HURDLES</h2>
              <h2 style={{ color: '#FF6645' }}>{hurdlesQuestion}</h2>

              <div className="hurdles">
                <ul>
                  {enablers.map((data, index) => (
                  {[...individualAnswer4]?.map((data, index) => (
                    data.answer != null ? <li key={index}>{ data.answer }</li> : ""
                    data ? <li key={index}>{data}</li> : ""
                  ))}
                </ul>
              </div>
            </div> */}
            {/* <div className="hurdles-enablers">
              <h2 style={{ color: '#27282A' }}>ENABLERS</h2>
              <h2 style={{ color: '#73B76F' }}>{enablersQuestion}</h2>
              <div className="enablers">
                <ul>
                  {hurdles.map((data, index) => (
                  {[...individualAnswer3]?.map((data, index) => (
                    data.answer != null ? <li key={index}>{ data.answer }</li> : ""
                    data ? <li key={index}>{data}</li> : ""
                  ))}
                </ul>
              </div>
            </div> */}


            <div style={{ height: "40px" }}></div>

          </div>
        </div>
            {/* {showInvite()}
            {showTestimonial()} */}
      </div>
    )
  } else {
    return (
      // <div style={{backgroundColor:"#435B63"}}>
      <div>
        <div className="individual-group">
          <button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
        </div>

        <div className="toggle-individual-group">
          <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
          <Switch
            checked={!toggleSection}
            onChange={toggleIndividualChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
        </div>

        <div className="container dashboard">
          <div className="details-container">

            {showTitleText()}

            <div className="progress-bar">
              <div className="si_ass_result">
                <div className="si_ass_progress">
                  <div className="transparent" style={{width: `${100-(Math.round(score))}%`}}></div>
                  {/* <div className="progress-line" style={{ left: score + '%' }}>
                    <div className="progress-text" style={windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div>
                  </div> */}
                  <span className="msi_score" style={getLeftMargin(score)}>{Math.round(score)}%</span>
                  <div className="score-arrow" style={{ left: Math.round(score) + '%' }}></div>
                </div>
                <div className="pervalue">
                  <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                  <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                  {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                  {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                </div>


                <div className="si_pro_header">
                  <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                  {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
                </div>

                {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                {/* </div> */}

                <div className="pervalue-text">
                  <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Less<br />Effective</p>
                  {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                  <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>Somewhat<br />Effective</p>
                  {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                  <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Highly<br />Effective</p>
                </div>

              </div>
            </div>

            {/* <div className="spacer"></div>
						<div className="spacer"></div> */}

            {/* {showScoreText(score)} */}
            <h3 className="pre-score-header">Your overall score is <b>{Math.round(score)}%</b>.<br/>Below are your scores per category.</h3>
            {/* <div className="result-sections">
	
							{
								sectionsAndSubScores.map((data, index) => (
								<div key={index} className="score-section">									
									<p className="score-txt">{scores[index]}%</p>
									<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={scores[index]} /></div>
									<p className="score-name">{data.section}</p>
								</div>)
							)}

						</div> */}

            {/* <h3 className="pre-score-header">Here's how you scored on your 4 Ps:</h3> */}
            {/* <div className="score-section-container"> */}
              {/* {sectionsAndSubScores?.map(sec => {
            			  return (
            			  <div key={sec.section} className="score-section">
            			    <p className="score-name">{sec.section}</p>        
						
            			    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={sec?.score} /><p className="score-txt" style={{ marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` }}>{sec.score}%</p></div>
            			  </div>)
            			})} */}
              {/* {sectionsAndSubScores?.map((sec) => {
                return (
                  <div key={sec.section} className="score-section">
                    <p className="score-txt" style={{
                      // marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` 
                    }}>{sec.score}%</p>
                    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={sec?.score} />
                    </div>
                    <p className="score-name">{sec.section}</p>
                  </div>)
              })} */}


            {/* </div> */}
              <ClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} />

            <div style={{ overflowX: "auto", padding: windowWidth < 551 ? "5px 0px" : "5px 10px" }}>

              <div className="mobile_scores">
                {/* <h2>INDIVIDUAL SCORES</h2> */}
                <div className="individual">

                  {
                    members.map((data, index) => (
                      <>
                        <div key={index} className="row" onClick={() => toggleDetails(data.id)}>
                          <div className="number">{index + 1}</div>
                          <div className="name">{data.fullName}</div>
                          <div className="percent">{Math.round(data.score)}%</div>
                          <div className="toggle">{toggleMoreIcon(data.id)}</div>
                        </div>
                        <div className="scores" style={toggleMoreDisplay(data.id)}>
                          <div className="section_rows">
                            <div className="section">{sections?.[0]?.section}</div>
                            <div className="section_score">{Math.round(data.subScore1)}%</div>
                          </div>
                          <div className="section_rows">
                            <div className="section">{sections?.[1]?.section}</div>
                            <div className="section_score">{Math.round(data.subScore2)}%</div>
                          </div>
                          <div className="section_rows">
                            <div className="section">{sections?.[2]?.section}</div>
                            <div className="section_score">{Math.round(data.subScore3)}%</div>
                          </div>
                          <div className="section_rows">
                            <div className="section">{sections?.[3]?.section}</div>
                            <div className="section_score">{Math.round(data.subScore4)}%</div>
                          </div>
                          {/* <div className="section_rows">
                            <div className="section">{sections?.[4]?.section}</div>
                            <div className="section_score">{Math.round(data.subScore5)}%</div>
                          </div> */}
                        </div>
                      </>
                    )
                    )}

                </div>

              </div>

              <table>
                <thead>
                  <tr className="header">
                    <th className="hash">#</th>
                    <th>Name</th>
                    {
                      sectionsAndSubScores.map((data, index) => (
                        <th key={index} className="center-cell">{data?.section?.toLowerCase()} (%)</th>
                      )
                      )}
                    <th className="avg">Avg (%)</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    members.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="medium">{data.fullName}</td>
                        {
                          sectionsAndSubScores.map((section, index) => {
                            let subScore;
                            if (index == 0) { subScore = data.subScore1; }
                            else if (index == 1) { subScore = data.subScore2; }
                            else if (index == 2) { subScore = data.subScore3; }
                            else if (index == 3) { subScore = data.subScore4; }
                            else if (index == 4) { subScore = data.subScore5; }
                            else if (index == 5) { subScore = data.subScore6; }
                            else if (index == 6) { subScore = data.subScore7; }
                            else if (index == 7) { subScore = data.subScore8; }
                            return <td key={index} className="center-cell">{Math.round(subScore)}</td>
                          }
                          )}
                        <td className="center-cell">{Math.round(data.score)}%</td>
                      </tr>
                    )
                    )}

                  <tr className='footer'>
                    <th className="center-cell">&nbsp;</th>
                    <th className="church-average">Team Average</th>
                    {
                      sectionsAndSubScores.map((data, index) => (
                        <th key={index} className="center-cell">{scores[index]}</th>
                      )
                      )}
                    <th className="center-cell">{score}%</th>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr />

            <h2>Detailed Report</h2>

            {tops?.length > 0 && <div className="most-answered">
              <p className="green">Where you are doing well</p>
              {tops.map((data, index) => (
                <div key={index} className="most-answered-detail">
                  <div className="list-item-number">{index + 1}.&nbsp;</div>
                  <div className="list-item-text">{data.question}</div>
                </div>
              ))}
            </div>}

            {bottoms?.length > 0 && <div className="most-answered">
              <p className="red">Where you need to improve</p>
              {bottoms.map((data, index) => (
                <div key={index} className="most-answered-detail">
                  <div className="list-item-number">{index + 1}.&nbsp;</div>
                  <div className="list-item-text">{data.question}</div>
                </div>
              ))}
            </div>}

            <div className="alignment">
              <Tabs members={members} questions={questions} sections={sections} />
            </div>
            <div className='urg-score'>
              {/* <UrgencyScore {...{ urgencyComponentProps: urgencyComponentPropsQ1Team }} /> */}
              {/* <UrgencyScore {...{ urgencyComponentProps: urgencyComponentPropsQ2Team }} /> */}
            </div>

            {/* <div className="hurdles-enablers">
              <h2 style={{ color: '#FF6645' }}>HURDLES</h2>

              <div className="hurdles">
                <ul>
                  {enablers.map((data, index) => (
                  {teamAnswers4?.map((data, index) => (
                    data.answer != null ? <li key={index}>{ data.answer }</li> : ""
                    data ? <li key={index}>{data}</li> : ""
                  ))}
                </ul>
              </div>
            </div> */}

            {/* <div className="hurdles-enablers">
              <h2 style={{ color: '#73B76F' }}>ENABLERS</h2>
              <div className="enablers">
                <ul>
                 // {hurdles.map((data, index) => (
                  {teamAnswers3?.map((data, index) => (
                    data.answer != null ? <li key={index}>{ data.answer }</li> : ""
                    data ? <li key={index}>{data}</li> : ""
                  ))}
                </ul>
              </div>
            </div> */}



            <div style={{ height: windowWidth > 830 ? "40px" : "20px" }}></div>

          </div>




        </div>
        {/* {showLegacyInvite()} */}
        {/* {showInvite()} */}
        {/* {showTestimonial()} */}

        {/* {showSchedule()} */}


      </div>
    );
  }


}
export default Dashboard;









