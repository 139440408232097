import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import churchImg from '../../Assets/Images/church.png';
import profitImg from '../../Assets/Images/for-profit.png';
import nonProfitImg from '../../Assets/Images/nonprofit.png';
import instituteImg from '../../Assets/Images/institute.png';
import rightTickImg from '../../Assets/Images/right-tick.png'; 



const categories = [
  { id: 1, img: churchImg, name: "Church", 
    text: ["Mentors come alongside of you, help you realize your potential, and fan the flame of your ability.", 
    "As trusted allies, they are friendly faces who encourage you to grow, develop and change."]
  },
  { id: 2, img: instituteImg, name: "Educational Institution", 
    text: ["Administrators help establish the plan and then follow it as it is laid out.", 
    "They are good with details, procedures and budgets. Administrators focus on management and tend to be averse to taking risks."]
  },
  { id: 3, img: nonProfitImg, name: "Non-Profit Organization", 
    text: ["Catalysts challenge you, and so take you to new ground. They innovate and experiment in life.", 
    "As trusted critics, Catalysts seem to light fires under people, spurring them on to unconventional or entirely new methods."]
  },
  { id: 4, img: profitImg, name: "For-Profit Business", 
    text: ["Relationals are motivated by harmony and are good team builders. They highly value grace and mercy and can be quite loving and warm.", 
    "Relationals’ decisions are guided by deep-seated values and personal connections to others."]
  },
  
];


const PreAssessment = () => {
  
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [selected, setSelected] = useState(-1);

  function goToAssessmentPage() {
    navigate("/assessment/" + id + "/814?" + searchParams, { replace: true });
  }

  return <div className="container pre-assessment">
    <h1>Please confirm the nature of your organization</h1>
    <div className="assessment-categories">
      {
        categories.map((item, index) => {
          return (
            <div className={`category ${selected != -1 ? selected == index ? 'selected' : 'non-selected' : ''}`} onClick={() => setSelected(index)}>
              <div className={`right-tick-img-container`} style={selected == index ? {} : { display: 'none' }}>
                <img src={rightTickImg} alt="" />
              </div>
              <div className="img-container">
                <img src={item['img']} alt="" />
              </div>
              <p className="text">{item['name']}</p>
            </div>)
        })
      }
    </div>

    <div className="button" style={selected != -1 ? { visibility: 'visible' } : { visibility: 'hidden' }}>
      <Button className="cta" onClick={goToAssessmentPage}>Contiune</Button>
    </div>
  </div>
}

export default PreAssessment;

