import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressGrid from '../ProgressGrid/ProgressGrid.jsx';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const ProgressBarSingle = (props) => {

  const totalNumOfQuestions = props?.totalNumOfQuestions;
  const sections = props?.sections;
  const currentSectionName = props?.currentSectionName;

  const { windowWidth } = useWindowDimensions();

  let percentageColor = '#EB1C22';
  let activeColor = '##EB1C22';
  let passiveColor = '#343333';
  // let sectionstyle         = { color: activeColor, fontWeight: '700' };

  let progress = Math.round(((props?.currentProgress + 1) / totalNumOfQuestions * 100));

  return (
    <div>
      <div className="progress-bar" style={{ maxWidth: windowWidth < 830 ? "75%" : "100%" }}>
        <div className="bar-section" style={{ width: "100%" }}>
          <p className="progress-percentage" style={{ width: `${progress}%`, color: percentageColor }}><span>{progress}%</span></p>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={parseInt(progress)} /></div>
          {/* <ProgressGrid progress={progress} /> */}

          <div className="sections" style={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
            {sections?.map((sec, idx) => {
              return <p key={idx} style={{
                color: currentSectionName?.toLowerCase().trim() === sec.section.toLowerCase().trim() ? activeColor : passiveColor, textTransform: "uppercase", textAlign: "center",
                // width: `${100/sections?.length}%` 
              }} className="section-name">{sec.section}</p>
            })}
          </div>

        </div>
      </div>

    </div>
  );
}
export default ProgressBarSingle;
