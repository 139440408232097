import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import AdditionalQuestionContext from "../../Store/AdditionalQuestionContext";

const FirstForm = () => {

	const { id } = useParams();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const addCtx = useContext(AdditionalQuestionContext);

	const [avgWeeklyAttd, setAvgWeeklyAttd] = useState(addCtx?.form1Details?.avgWeeklyAttd);
	const [zipCode, setZipCode] = useState(addCtx?.form1Details?.zipCode);
	const [formQuestions, setFormQuestions] = useState("");
	const [zipCodeError, setZipCodeError] = useState(false);
	const [weeklyAttdError, setWeeklyAttdError] = useState(false);
	const [zipCodeValid, setZipCodeValid] = useState(true);
	const [weeklyAttdValid, setWeeklyAttdValid] = useState(true);


	useEffect(() => {
		const updateForm1CtxDetails = (fieldName, value) => {
			if (value !== addCtx.form1Details?.[fieldName]) {
				addCtx.addForm1Details(prev => ({ ...prev, [fieldName]: value }));
			}
		};

		updateForm1CtxDetails("avgWeeklyAttd", avgWeeklyAttd);
		updateForm1CtxDetails("zipCode", zipCode);

	}, [avgWeeklyAttd, zipCode]);

	useEffect(() => {
		let form1Details = addCtx.form1Details;

		if (form1Details?.avgWeeklyAttd?.length) setAvgWeeklyAttd(form1Details.avgWeeklyAttd);
		if (form1Details?.zipCode?.length) setZipCode(form1Details.zipCode);

	}, [addCtx.form1Details]);

	console.log("addCtx.form1Details", addCtx.form1Details);
	console.log("formQuestions", formQuestions);

	const pageId = 27;

	useEffect(() => {

		const getFormQuestions = () => {
			fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
				method: 'GET',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json' }
			}).then(response => {
				return response.json();
			}).then(data => {
				if (data.status == "success") {
					console.log(data);
					setFormQuestions(data?.data.questions)
					// setIsLoading(false);
				} else {
					// setOpenSnackbar(true);
				}
			}).catch((err) => console.error("Error while fetching form questions...", err));
		}

		getFormQuestions();

	}, [id]);


	const submitFirstForm = (e) => {
		e.preventDefault();
		const jsonBody = {
			questions: [
				{
					// "id": formQuestions?.[0]?.id,
					"id": 650,
					"answer": avgWeeklyAttd,
					"answerTxt": null
				},
				{
					// "id": formQuestions?.[1]?.id,
					"id": 651,
					"answer": zipCode,
					"answerTxt": null
				},
			],
		}
		const clientId = process.env.REACT_APP_CLIENT_ID;
		fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(jsonBody)
		}).then(response => {
			return response.json();
		}).then(data => {
			if (data.status === "success") {
				navigate("/assessment/" + id + "/28?" + searchParams, { replace: true });
				console.log("data", data);
			} else {
				// setIsSaving(false);
				// setOpenSnackbar(true);
			}
		}).catch((err) => console.error("Failed to send form details...", err));
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (zipCode.length <= 7 && zipCode.length >= 5 && +avgWeeklyAttd < 100000 && +avgWeeklyAttd > 99) {
			submitFirstForm(e)
		} else {
			showErrors();
		}
	}

	const isNumber = inputValue => /^\d+$/.test(inputValue);

	const showErrors = () => {
		if (!zipCode) {
			setZipCodeError(true);
		}

		if (zipCode.length > 7 || zipCode.length < 5) {
			setZipCodeValid(false);
		}

		if (!avgWeeklyAttd || !isNumber(avgWeeklyAttd)) {
			setWeeklyAttdError(true);
		}

		if (+avgWeeklyAttd < 100 || +avgWeeklyAttd >= 100000) {
			setWeeklyAttdValid(false);
		}

	}

	const showHintText = (field) => {
		if (field == "weeklyAttendance") {
			if (weeklyAttdError) {
				return <div className="error-text">Please confirm your avg. weekly attendance.</div>
			}
			if (!weeklyAttdValid) {
				return <div className="error-text">Maximum avg. weekly attendance can range from 100 to 100,000.</div>
			}
		}

		if (field == "zipCode") {
			if (zipCodeError) {
				return <div className="error-text">Please confirm your ZIP code.</div>
			}
			if (!zipCodeValid) {
				return <div className="error-text">Invalid ZIP code.</div>
			}
		}
	}

	const removeHintText = (field, e) => {
		if (e.target.closest(".form-detail")) {
			document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
			e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
		}

		if (field == "weeklyAttendance") {
			setWeeklyAttdError(false);
			setWeeklyAttdValid(true);
		}

		if (field == "zipCode") {
			setZipCodeError(false);
			setZipCodeValid(true);
		}
	}

	return (
		<div className="firstform-container">
			<div className="firstform">
				<h1>Before we begin, tell us a little about your church.</h1>
			</div>
			<div className="form">
				<div className="form-detail attendance">
					<p className="input-title">Average weekly attendance<span className="required-field">*</span></p>
					<TextField className="form-details-text" placeholder="E.g.: 800" value={avgWeeklyAttd} onFocus={(e) => removeHintText("weeklyAttendance", e)} onChange={(e) => setAvgWeeklyAttd(e.target.value)}></TextField>
					{showHintText("weeklyAttendance")}
				</div>

				<div className="form-detail zip">
					<p className="input-title">ZIP code<span className="required-field">*</span></p>
					<TextField className="form-details-text" placeholder="E.g.: 11618" value={zipCode} onFocus={(e) => removeHintText("zipCode", e)} onChange={(e) => setZipCode(e.target.value)}></TextField>
					{showHintText("zipCode")}
				</div>
			</div>
			<div className="cta">
				<Button className="cta-btn" onClick={(e) => handleSubmit(e)}>LET'S GO</Button>

				{/* <Button className="cta-btn" onClick={(e) => submitFirstForm(e)}>LET'S GO</Button> */}
			</div>

			<div className="firstform-footer">
				<p className="firstform-footer-text">This benchmarking data will help visualize how you compare with similarly-sized American churches.</p>
			</div>
		</div>
	)
}

export default FirstForm;
