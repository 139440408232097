import { useState, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useWindowDimensions from "../../Hooks/useWindowDimensions";

import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const DonutChartAccordion = (props) => {

  const { windowWidth } = useWindowDimensions();

  let fontSize = windowWidth > 645 ? 14 : 12;
  let fontWeight = windowWidth > 645 ? 700 : 600;
  let size = windowWidth > 645 ? 80 : 50;
  // const[isLoading, setIsLoading] = useState(false);

  console.log("DonutChartAccordion", props);

  const { answer, order, dashboard, dashQuestions } = props;

  const newData = props.data?.filter((val) => val.name !== props.answer.name);

  const selectedOption = props.data?.find((item) => item.name === props.answer.name);
  console.log("selectedOption", selectedOption);

  const showAnswer = (answer) => {
    switch (answer) {
      case 1:
        return <p className="dashboard-answer" style={{ color: "#ED2028", }}>Strongly Disagree</p>;
      case 2:
        return <p className="dashboard-answer" style={{ color: "#F17E7E", }}>Disagree</p>;
      case 3:
        return <p className="dashboard-answer" style={{ color: "#FDBF04", }}>Neutral</p>;
      case 4:
        return <p className="dashboard-answer" style={{ color: "#73B76F", }}>Agree</p>;
      case 5:
        return <p className="dashboard-answer" style={{ color: "#429653", }}>Strongly Agree</p>;
      default:
        break;
    }
  }


  const answerData = [{
    name: selectedOption.name, y: selectedOption.y, selected: true, size: "180%", color: {
      radialGradient: {
        cx: 0.5,
        cy: 0.5,
        r: 0.5
      },
      stops: [
        [0, '#ED2028'],
        [1, '#8C4E50'],
      ]
    }
    , dataLabels: {
      color: "#CB3137",
      style: { fontWeight: "normal", fontSize: `${fontSize}px`, },
      distance: windowWidth < 830 ? 35 : 40,
    }
  }];

  const finalData = [...newData, ...answerData];
  console.log("FINALDATA", finalData);


  const options = {
    chart: {
      type: "pie",
      spacing: windowWidth < 830 ? [15, 15, 15, 15] : [50, 50, 50, 50],
      events: {
        load: function () {
          const graphic = this.series[0].data[finalData?.length - 1].graphic;
          // console.log("graphic", graphic);
          const prevR = graphic?.r;
          graphic.attr({
            r: prevR + size + 20
          });
        }
      }
    },
    title: { text: '' },

    tooltip: {
      // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      pointFormat: '<b>{point.percentage:.1f}%</b>',
      enabled: false, // disable onHover tooltip box 
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        size: `${size}%`,
        innerSize: "40%",
        depth: 30,
        // When setting the border width to 0, there may be small gaps between the slices due to SVG antialiasing 
        // artefacts. To work around this, keep the border width at 0.5 or 1, but set the borderColor to null instead.
        borderWidth: 1,
        borderRadius: 0,
        borderColor: null,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          distance: windowWidth < 830 ? 20 : 40,
          enabled: true,
          useHTML: true,
          formatter: function () {
            let label = this.point.name;
            let labelName = this.point.name.split(' ');
            let newLabelName = "";
            if (windowWidth < 830 && label?.length > 10 && labelName?.length >= 2) newLabelName = labelName?.join('<br/>');
            else newLabelName = this.point.name;
            return '<div style="display:flex; flex-direction:column; justify-content: center; text-align: center; font-family: Barlow, sans-serif;">' + "<p class='label-name' style=''>" + newLabelName + "</p><p class='label-percent' style=''>" + Math.round(this.point.percentage) + "%</p></div>";
          },
          // format: '<b>{point.name}</b><br/><p style="text-align: center;">{point.percentage:.1f}%</p>',
          connectorColor: 'none',
          style: {
            fontSize: `${fontSize}px`,
            fontWeight: `${fontWeight}`,
            lineHeight: "16.8px",
            color: "#6D6262"
          }
        }
      }
    },
    credits: { enabled: false },

    colors: ['#E6E6E6', '#A69797', '#6D6262', '#CECCCC'],
    series: [{
      states: {
        inactive: { opacity: 1 },
        hover: {
          // halo: { opacity: 0, },
          // brightness: 0,
          enabled: false,
        }
      },
      data: finalData
      // data: [
      //   { name: "Neutral", y: 25, color: "#A69797" },
      //   { name: "Agree", y: 25, color: "#6D6262" },
      //   {
      //     name: props.answer.name, y: 25, selected: true, size: "180%", color: {
      //       radialGradient: {
      //         cx: 0.5,
      //         cy: 0.5,
      //         r: 0.5
      //       },
      //       stops: [
      //         [0, '#ED2028'],
      //         [1, '#8C4E50'],
      //       ]
      //     }
      //     , dataLabels: {
      //       color: "#CB3137",
      //       style: { fontWeight: "normal", fontSize: `${fontSize}px`, },
      //       distance: 80
      //     }
      //   },
      //   { name: "Strongly Disagree", y: 10, color: "#E6E6E6" },
      //   { name: "Disagree", y: 15, color: "#CECCCC" },


      //   //   20,20,20,20,20
      // ],

    }]
  };

  const neutralText = (ans) => {
    if (ans.toLowerCase() === "neutral") return "feel neutral about";
    else return ans.toLowerCase() + " that";
  }

  return (
    // <div className="accordion-chart-container" style={{ width: "100%", height: "342px" }}>
    <div className="accordion-chart-container chart-container" style={{ width: "100%", height: "auto" }}>

      {order === 1 && <div className="chart-heading">
        <h1 className="exception"><span>{answer.y}% of similarly-sized churches</span> also {neutralText(answer.name.toLowerCase())}</h1>
        <p>“The community around us is aware of our mission.”</p>
      </div>}

      {order === 2 && <div className="chart-heading">
        <h1><span>{answer.y}% of similarly-sized churches</span> also hosted {answer.name.toLowerCase()} engagement opportunities in the past year.</h1>
        {/* <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p> */}
      </div>}

      {order === 3 && <div className="chart-heading">
        <h1 className="exception"><span>{answer.y}% of similarly-sized churches</span> also had {answer.name.toLowerCase()} community members participate in engagement opportunities.</h1>
        {/* <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p> */}
      </div>}

      {order === 4 && <div className="chart-heading">
        <h1><span>{answer.y}% of similarly-sized churches</span> also agreed that their community engagement was {answer.name.toLowerCase()}.</h1>
        {/* <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p> */}
      </div>}

      {order === 5 && <div className="chart-heading">
        <h1 className="exception"><span>{answer.y}% of similarly-sized churches</span> also {neutralText(answer.name.toLowerCase())}</h1>
        <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p>
      </div>}

      <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>


      {dashboard && dashQuestions?.map(q => {

        return <div key={q.id} className="dashboard-qa-container" style={{ color: "#27282A", }}>
          <p className="dashboard-question" style={{ width: "75%" }}>{q.question}</p>
          {showAnswer(q.answer)}
        </div>
      })}

    </div>
  )
}


export default DonutChartAccordion;
