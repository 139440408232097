import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../.././Assets/Images/testimonial.jpg';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../.././Assets/Images/leftQuote.png';
import bottomQ from '../.././Assets/Images/rightQuote.png';
import quotation from "../.././Assets/Images/quote-single.png";

const Testimonial = () => {

  const { id } = useParams();

  return (
    <div className="testimonial-container">
        <div className="testimonial">
        <div className="testi">
            <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>This assessment was designed keeping churches in mind. Assess your bookkeeping practices to better align your financial footprints with your church’s mission. This assessment will help you better manage your employees and your finances.</p>
            
            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{transform:'rotate(180deg)'}} alt="quote icon" /></div></div>
            
          </div>
        <div>
          <div className="avatar">
            <img src={testimonialImage} alt="pastor" width={125} height={125} />
          </div>
        </div>
        <div style={{display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center'}}>          
          <div className="designation">
          {/* Chris Martinez<p className="designation-inner">Lead Pastor, Lakeside Church</p>  */}
          <p className="designation-inner">The ACS Technologies Team</p> 
          </div>
        </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
  );
}

export default Testimonial;
