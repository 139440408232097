import React, { useEffect } from "react";
import TickImg from "../../Assets/Images/success-tick.svg";
import GraphImg from '../../Assets/Images/graph_Icon.png'
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

const InviteThankYou = () => {
  const { id } = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#292829";
  }, []);

  return (
    <>
      <div className="container thank-you barlow-bold">
        <div className="details-container">
          <div className="tick">
            <img src={TickImg} alt="" />
          </div>
          {/* <div className="tick">&#10003;</div> */}
          <h1>You have successfully sent invites!</h1>
          {/* <p>
            You'll be notified each time one of your team members takes the test
            so that you can intentionally simplify generosity in your church.
          </p> */}
          {/* <p>You'll be notified as your Board Members take the test so you can review aggregate and individual results.</p> */}
          {/* <p>You'll be notified as your team is taking the test so that
				 you can review aggregate results and individual results.</p> */}
          <div className="back-btn">
            <Link to={"/results/" + id}>INVITE MORE LEADERS</Link>
          </div>
        </div>
      </div>

      {/* <div className="schedule-container">
		<img src={GraphImg} alt="" />
        <h4>
          Schedule a call to have our team walk you through your report and see
          how you can accelerate generosity in your church.
        </h4>
        <button className="cta-btn">Schedule a call</button>
      </div> */}
    </>
  );
};

export default InviteThankYou;
