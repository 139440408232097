import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute, useParams } from "react-router-dom";
import TagManager from 'react-gtm-module';

import CircularProgress from '@mui/material/CircularProgress';

import Header from './Components/Layout/Header/Header.jsx';
import Footer from './Components/Layout/Footer/Footer.jsx';
import LeftLogo from './Components/Layout/LeftLogo';
import Start from './Pages/Start/Start.jsx';
import Assessment from './Pages/Assessment/Assessment.jsx';
import AssessmentV3 from './Pages/AssessmentV3/AssessmentV3.jsx';
import AssessmentAdditional from './Pages/AssessmentAdditional/AssessmentAdditional.jsx';
import Details from './Pages/Details/Details.jsx';
import Results from './Pages/Results/Results.jsx';
import Dashboard from './Pages/Dashboard/Dashboard.jsx';
import Invite from './Pages/Invite/Invite.jsx';
import InviteThankYou from './Pages/InviteThankYou/InviteThankYou.jsx';
import PreAdditionalInfo from './Pages/PreAdditionalInfo/PreAdditionalInfo.jsx';
import PreAdditionalQuestions from './Pages/PreAdditionalQuestions/PreAdditionalQuestions.jsx';
import PreAssessment from './Pages/PreAssessment/PreAssessment';
// import { AssessmentProgressContextProvider } from './Store/AssessmentProgressContext';
// import AssessmentProgressSections from './Components/AssessmentProgressSections';

import { PreAdditionalQuestionContextProvider } from './Store/PreAdditionalQuestionContext';
import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';
import { AssessmentQuestionContextProvider } from './Store/AssessmentQuestionContext';
import { QuestionsProgressContextProvider } from './Store/QuestionsProgressContext';
import DonutChart from './Components/DonutChart/DonutChart.jsx';
import Intro from './Pages/Intro/Intro.jsx';
import FirstForm from './Pages/FirstForm/FirstForm.jsx';
import Charts from './Pages/Chart/Chart.jsx';
import AssessmentAdditionalV3 from './Pages/AssessmentAdditionalV3/AssessmentAdditionalV3.jsx';

// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-P5Q48LR7'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [resultPage, setResultsPage] = useState(false);

  useEffect(() => {
    const url = window.location.pathname;
    console.log("TRUE RESULTS URL", url);
    if (url.split("/").includes("results")) {
      console.log("TRUE RESULTS");
      setResultsPage(true);
    } else {
      console.log("FALSE RESULTS");

      setResultsPage(false);
    }
  }, [])

  useEffect(() => {
    getClient();
  }, []);

  const getClient = () => {
    setIsLoading(false);
  }

  if (isLoading) {
    return <div className="container details loading"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div>
  }

  return (
    <div

      className={"App"}>
      {/* <AssessmentProgressContextProvider> */}
      <BrowserRouter>
        <AssessmentQuestionContextProvider>
          <AdditionalQuestionContextProvider>
            <PreAdditionalQuestionContextProvider>
              <QuestionsProgressContextProvider>
                <Header style={resultPage ? { backgroundColor: "#ffffff" } : { backgroundColor: "#ffffff" }} />
                <main className="main">
                  <LeftLogo/>
                  {/* <AssessmentProgressSections /> */}
                  <Routes>
                    <Route path="/" exact index element={<Start setName={setName} />} />
                    <Route path="/start" exact element={<Start setName={setName} />}></Route>
                    <Route path="/before-you-start/:id" exact element={<PreAssessment />}></Route>
                    <Route path="/before-you-start" exact element={<PreAssessment />}></Route>
                    <Route path="/assessment" exact element={<Assessment />}></Route>
                    <Route path="/assessment/:id/:pageId" exact element={<AssessmentV3 />}></Route>
                    {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
                    {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
                    <Route path="/pre-additional-info/:id" exact element={<PreAdditionalInfo />}></Route>
                    <Route path="/pre-additional-questions/" exact element={<PreAdditionalQuestions />}></Route>
                    {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
                    <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route>
                    {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
                    <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditionalV3 />}></Route>
                    <Route path="/details/:id" exact element={<Details />}></Route>
                    <Route path="/results/:id" exact element={<Results />}></Route>
                    <Route path="/invite-team/:id" exact element={<Invite />}></Route>
                    <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
                    <Route path="/dashboard/:id" exact element={<Dashboard />}></Route>
                    <Route path="/chart/:id/:pageId" exact element={<DonutChart />}></Route>
                    {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
                    <Route path="/intro/:id" exact element={<Intro />}></Route>
                    <Route path="/intro" exact element={<Intro />}></Route>
                    <Route path="/first-form/:id" exact element={<FirstForm />}></Route>
                    <Route path="/first-form" exact element={<FirstForm />}></Route>
                    <Route path="/charts/:id/:pageId" exact element={<Charts />}></Route>

                  </Routes>
                </main>
                <Footer />
              </QuestionsProgressContextProvider>
            </PreAdditionalQuestionContextProvider>
          </AdditionalQuestionContextProvider>
        </AssessmentQuestionContextProvider>
      </BrowserRouter>
      {/* </AssessmentProgressContextProvider> */}
    </div>
  );
}

export default App;
